<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        {{ header }}
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="sort">
        <b-form-group
          :label="$t('Management.Sorts.Name')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Sorts.Name')"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="sort.name"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Sorts.Name') })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Sorts.Short')"
          label-for="short"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Sorts.Short')"
            rules="required"
          >
            <b-form-input
              id="short"
              v-model="sort.short"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Sorts.Short'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Sorts.Group')" label-for="group">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Sorts.Group')"
            rules="required"
          >
            <v-select
              id="group"
              v-model="sort.group"
              :placeholder="
                $t('Global.Select', { subject: $t('Management.Sorts.Group') })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="Groups"
              :disabled="!can"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ label }">
                <div>{{ $t(`Management.Sorts.Groups.${label}`) }}</div>
              </template>
              <template #selected-option="{ label }">
                <div>{{ $t(`Management.Sorts.Groups.${label}`) }}</div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

          <b-form-group
            :label="$t('Management.Sorts.Active')"
            label-for="sortActive"
          >
            <b-form-checkbox
              id="sortActive"
              v-model="sort.active"
              :value="true"
              :unchecked-value="false"
              :disabled="!can"
            >
              {{ $t('Management.Sorts.Active') }}
            </b-form-checkbox>
          </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from 'vue-select'
import { BButton, BSidebar, BFormInput, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import { Groups } from '@/data/enums'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    sort: {
      type: Object,
      default: () => null,
    },
    can: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    required,
    Groups,
  }),

  methods: {
    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },
    async submit() {
      const valid = await this.$refs.rules.validate()

      if (valid) {
        this.$emit('submit')
        return
      }
      this.$alert.invalid()
    },
  },
}
</script>
<style lang="scss">
  .custom-control-input {
    z-index: 1;
  }
</style>
  