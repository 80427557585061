<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="ListIcon" size="21" />
      {{ $tc('Global.New', 1) }} {{ $t('Management.Sorts.Sort') }}
    </b-button>
    <Form
      ref="form"
      :sort="sort"
      :can="$can($acl.action.Create, $acl.subjects.Sorts)"
      :header="$tc('Global.New', 1) + ' ' + $t('Management.Sorts.Sort')"
      @submit="submit"
      @reset="reset"
    />
  </div>
</template>

<script>
import { BButton, VBToggle } from 'bootstrap-vue'

import { Groups } from '@/data/enums'
import Form from './form.vue'

export default {
  components: {
    BButton,
    Form,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  data: () => ({
    sort: {
      name: null,
      short: null,
      group: null,
      active: true,
    },
    Groups,
  }),

  methods: {
    reset() {
      this.sort = {
        name: null,
        short: null,
        group: null,
        active: true,
      }
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.post('sorts', {
          name: this.sort.name,
          short: this.sort.short,
          group: this.sort.group,
          active: this.sort.active,
        })
        this.$alert.create(`${data.name}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
